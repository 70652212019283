html {
  font: 16px '微软雅黑';
  box-sizing: border-box;
}

@media screen and (max-width: 370px) {
  html {
    font-size: 14px;
  }
}

html * {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, .8);
}

.hide {
  display: none !important;
}

.banner {
  max-height: 485px;
  width: 100%;
  text-align: center;
  line-height: 0;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input {
  padding: 0;
  margin: 0
}

img {
  max-width: 100%;
  max-height: 100%;
}

.color-white {
  color: #fff;
}

.color-orange {
  color: #fb9400;
}

.color-red {
  color: #f00;
}

.color-blue {
  color: #1d48f2;
}

.tc {
  text-align: center;
}

.tl {
  text-align: left;
}

#root {
  padding-top: 5.25rem;
}

#header {
  height: 5.25rem;
  transition: all ease .2s;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

#header .head-fix {
  height: 100%;
  padding: 0.5rem;
  background: #fb9400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}

.logo {
  display: inline-block;
  width: 6rem;
  height: 2.5rem;
  background: url("/logo.png") center center no-repeat;
  background-clip: content-box;
  background-size: contain;
}

#header .head-fix>div {
  display: inline-flex;
  justify-content: inherit;
  flex-wrap: nowrap;
  align-items: center;
}

#header .blue {
  color: #1d48f2;
}

#header .userInfo .blue {
  width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

#header .loginbts a {
  display: inline-block;
  font-weight: bold;
  color: #1d48f2;
  padding: 0.5rem 1.5rem;
}

#header .loginbts a:active {
  background-color: rgba(29, 72, 242, 0.8);
  color: #fff;
}

#header button {
  padding: 0.625rem;
}

#header .loginbts .am-button {
  background: #1d48f2;
  color: #fff;
  border-radius: 5px;
}

#header .nav-button {
  padding: 0.5rem;
  margin-left: 1rem;
  height: 2.5rem;
}

#header .brand {
  position: relative;
  display: block;
  padding-top: 3px;
}

#header .brand span {
  display: block;
  width: 1.25rem;
  height: 2px;
  background-color: #1d48f2;
  margin-bottom: 5px;
  transition: transform 300ms ease-in-out 0s;
}

#header .brand-open span:first-child {
  transform: translate(2px) rotate(45deg) scale(1.05);
  transform-origin: left top;
}

#header .brand-open span:nth-child(2) {
  opacity: 0;
}

#header .brand-open span:last-child {
  margin-bottom: 0px;
  transform: translate(2px) rotate(-45deg) scale(1.05);
  transform-origin: left bottom;
}

#header .nav {
  max-height: calc(100vh - 5.25rem);
}

#header .nav .nav-content {
  max-height: calc(100vh - 5.25rem);
  overflow: auto;
}

.nav {
  position: absolute;
  top: 5.25rem;
  left: 0;
  width: 100%;
  opacity: 1;
  z-index: 1;
  transform: translateY(-100%) translateZ(0);
  transition: transform 350ms cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
}

.nav .nav-back {
  display: none;
  position: absolute;
  width: 100%;
  height: calc(100vh - 5.25rem);
  opacity: 0;
  background: #24303e;
  z-index: -1;
  transition: opacity 200ms ease 0s;
}

.nav-show {
  transform: translateY(0px) translateZ(0px);
}

.nav-show .nav-back {
  display: block;
  opacity: 0.5;
  transition: opacity 200ms ease;
}


.nav .nav-item {
  background-color: #24303e;
}

.nav .nav-item img {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 2rem;
}

.nav .nav-item .nav-item-link {
  display: flex;
  height: 4rem;
  padding: 0 1rem;
  border-bottom: 1px solid #000;
  justify-content: space-between;
  align-items: center;
}

.nav .nav-item .nav-item-link a {
  color: #fff;
  display: inline-flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.nav .nav-item .nav-item-link>span {
  color: #fff;
}

.nav .nav-item .nav-item-link .lang {
  color: #1d48f2;
  width: 2.5rem;
  justify-content: flex-end;
}

.nav .nav-item .arow {
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid #fff;
  margin-right: 0.4rem;
  border-width: 2px 0 0 2px;
  transform: rotate(-135deg);
  transition: transform 200ms ease 0s;
}

.nav .nav-item_open .arow {
  border-color: #1d48f2;
  transform: rotate(45deg);
}

.nav .nav-item .nav-item_child {
  display: none;
}

.nav .nav-item_open {
  background-color: #fff;
}

.nav .nav-item_open .nav-item_child {
  display: block
}

.nav .nav-item_child a {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 1rem;
  border-bottom: 1px solid #000;
}

.nav .nav-item_open .nav-item-link span {
  color: #1d48f2;
  font-weight: bold;
}

.nav .nav-item_open .nav-item_child-link {
  color: #24303e;
}

.footer .nav {
  position: static;
  opacity: 1;
  transform: translateX(0) translateZ(0);
}

.footer .footer-head {
  height: 6rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  background-color: #24303e;
  border-bottom: 1px solid #000;
}

.footer .footer-concat {
  padding: 2.5rem 1.375rem;
}

.footer .footer-concat p {
  margin-bottom: 1.5rem;
  font-size: 0.85rem;
  line-height: 200%;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img-col2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-bottom: 5px;
}

.img-col2 img {
  max-width: 50%;
}

.img-col2 img:first-child {
  margin-right: 5px;
}

.blue-box {
  background: #24303e;
  color: #fff;
  padding: 2rem 1.375rem;
}

.container {
  padding: 0 1.375rem;
}

#load {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, .3);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1;
}

#load:after {
  content: '';
  width: 25vw;
  height: 25vw;
  max-width: 10rem;
  max-height: 10rem;
  border-radius: 50%;
  border: 5px solid #fb9400;
  border-bottom-color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  animation: loadingRotate 1s linear infinite;
}

@keyframes loadingRotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.inline-flex_between{
  display: inline-flex;
  justify-content: space-between;
}

.form .am-list input:not([type="checkbox"]),
.form .am-list textarea {
  width: 100%;
  height: 2.3rem;
  padding-left: 5px;
  border-radius: 5px;
  border: 1px solid #657281;
  background-color: transparent;
  outline: none;
  color: #fff;
}

.form .am-list input:not([type="checkbox"]):focus,
.form .am-list textarea:focus{
  border-color: #fb9400;
}

.form .am-list-item {
  margin-top: 1rem;
}

.form .list-item_code{
  display: flex;
  justify-content: space-between;
}

.form .list-item_code .code{
  display: inline-flex;
  width: 80px;
  justify-content: center;
  align-items: center;
  border: 1px solid #657281;
  border-radius: 5px;
  margin-left: 8px;
}

.form .am-list .error-input input {
  border-color: #f00;
}

.form .am-list .checkbox {
  display: flex;
  line-height: 1rem;
  padding: 10px 0;
  margin-bottom: 2.5rem;
}

.form .am-list .checkbox>span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.375rem;
  height: 1.175rem;
  border-radius: 5px;
  margin-right: 5px;
  background: #fb9400;
}

.form .am-list .checkbox.checkbox-checked>span:before {
  content: '';
  width: 0.3rem;
  height: 0.6rem;
  transform: rotate(45deg);
  border: 2px solid #fff;
  border-width: 0 2px 2px 0;
  position: relative;
  top: -2px;

}

.form .am-list textarea {
  height: 7.25rem;
}

.button {
  display: inline-block;
  width: 100%;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  color: #000;
  border-radius: 6px;
  font-weight: bold;
  background-color: #fb9400;
  border: none;
}

.button:disabled {
  background-color: gray;
}

.button.button-none{
  background: none;
  color: #1d48f2;
}
.button:active {
  opacity: .8;
}

.result-succ {
  display: flex;
  max-height: 70vw;
  padding: 2rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.result-succ .radio {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 5.25rem;
  height: 5.25rem;
  border-radius: 50%;
  background: #fb9400;
  margin-bottom: 1rem;
}

.result-succ .radio img {
  max-width: 60%
}

.translate {
  transform: translate3d(0px, 0px, 0px) !important;
  transition: opacity 1250ms ease 0ms, transform 1250ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
  opacity: 1 !important;
  width: 100%;
}

.tips{
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d9dadb;
  font-size: 0.875rem;
  background-color: #fff;
  color: #000;
}

.tips img{
  max-width: 1.5rem;
  margin-right: 0.625rem;
}